<template>
  <div>
    <v-row dense>
      <v-col md="12" cols="12">
        <v-form ref="form" class="multi-col-validation">
          <v-row dense>
            <v-col md="3" cols="12">
              <v-card flat dense>
                <v-toolbar dense dark color="primary">
                  <v-toolbar-title><h5 class="font-weight-light">SEARCHING SECTION</h5>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="mt-3">
                  <v-row dense>
                    <v-col md="12" cols="12">
                      <v-select
                        v-model="month_of_id"
                        class="mx-2"
                        :items="month_of_items"
                        item-value="id"
                        item-text="month_of"
                        label="Month Of"
                        required
                        :rules="rules.combobox_rule"
                        dense
                        @change="selected_month"
                      ></v-select>
                    </v-col>
                    <v-col md="12" cols="12">
                      <v-select
                        v-model="type_of_employee"
                        class="mx-2"
                        :items="['CMI','RCMI','CDI']"
                        label="Type of Incentives"
                        required
                        :rules="rules.combobox_rule"
                        dense
                        @change="selected_month"
                      ></v-select>
                    </v-col>
                    <v-col md="12" cols="12">
                      <v-select
                        v-model="batch_no"
                        class="mx-2"
                        :items="['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']"
                        label="Batch"
                        required
                        :rules="rules.combobox_rule"
                        dense
                        @change="selected_month"
                      ></v-select>
                    </v-col>

                    <!--                    <v-col md="12" cols="12" v-if="category_id > 0 && false">-->
                    <!--                      <v-autocomplete-->
                    <!--                        class="mx-2"-->
                    <!--                        v-model="search"-->
                    <!--                        :items="search_items"-->
                    <!--                        :loading="isLoading"-->
                    <!--                        @keyup.enter="searching($event.target.value)"-->
                    <!--                        hide-no-data-->
                    <!--                        hide-selected-->
                    <!--                        item-text="name"-->
                    <!--                        item-value="id"-->
                    <!--                        label="Search"-->
                    <!--                        placeholder="Press Enter to Search "-->
                    <!--                        :prepend-icon="icons.mdiAccountSearchOutline"-->
                    <!--                        @change="get_search_items_info"-->
                    <!--                      ></v-autocomplete>-->
                    <!--                    </v-col>-->
                    <v-col md="12" cols="12">
                      <h2>List of Available</h2>
                      <v-data-table
                        dense
                        class="mt-2"
                        :headers="headers"
                        :items="employee_items"
                        :height="400"
                        disable-pagination
                        hide-default-footer
                      >
                        <template v-slot:item="{ item }">
                          <tr @click="activerow(item)"
                              :class="{ 'info white--text': item.id === selectedDepositId }">
                            <td class="text-center">
                              {{ item.cm!=null?item.cm.name:item.name }}
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="9" cols="12">
              <v-card flat dense>
                <v-toolbar dense dark color="primary">
                  <v-toolbar-title><h5 class="font-weight-light">EMPLOYEE'S INFORMATION</h5>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="mt-3">
                  <v-row dense>
                    <v-col md="4" cols="12">
                      <v-text-field
                        v-model="full_name"
                        label="Full Name"
                        dense
                        outlined
                        readonly
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4" cols="12">
                      <v-text-field
                        v-model="branch"
                        label="Branch"
                        dense
                        outlined
                        readonly
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4" cols="12">
                      <v-text-field
                        v-model="position"
                        label="Position"
                        dense
                        outlined
                        readonly
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4" cols="12">
                      <v-text-field
                        v-model="status"
                        label="Status"
                        dense
                        outlined
                        readonly
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4" cols="12">
                      <v-text-field
                        v-model="date_of_employment"
                        label="Date of Employment"
                        dense
                        outlined
                        readonly
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4" cols="12">
                      <v-text-field
                        v-model="employment_duration"
                        label="Employee Duration"
                        dense
                        outlined
                        readonly
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-row>
                <v-col md="12" cols="12">
                  <v-card flat dense>
                    <v-toolbar dense dark color="primary">
                      <v-toolbar-title><h5 class="font-weight-light">COMPENSATION SECTION</h5>
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="mt-3">
                      <v-row dense>
                        <v-col md="6" cols="12">
                          <v-text-field
                            background-color=""
                            style="font-size: xx-large"
                            v-model="royalty"
                            label="Royalty"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="6" cols="12">
                          <v-text-field
                            background-color=""
                            style="font-size: xx-large"
                            v-model="other_incentives"
                            label="Other Incentives"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="12" cols="12">
                          <v-text-field
                            background-color="info"
                            style="font-size: xx-large"
                            v-model="gross_salary_formated"
                            label="Gross Incentives"
                            dense
                            outlined
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col md="12" cols="12">
                  <v-card flat dense>
                    <v-toolbar dense dark color="primary">
                      <v-toolbar-title><h5 class="font-weight-light">INCENTIVES DEDUCTION
                        SECTION</h5>
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="mt-3">
                      <v-row dense>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-if="status != 'Trainee'"
                            v-model="deduction_sss"
                            label="SSS #"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-if="read_loans_only"
                            v-model="deduction_loan"
                            label="Loans"
                            dense
                            outlined
                            readonly
                          ></v-text-field>
                          <v-text-field
                            v-else
                            v-model="deduction_loan"
                            label="Loans"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-if="read_auto_loans_only"
                            v-model="deduction_auto_loan"
                            label="Auto Loan"
                            dense
                            outlined
                            readonly
                          ></v-text-field>
                          <v-text-field
                            v-else
                            v-model="deduction_auto_loan"
                            label="Auto Loan"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-if="status != 'Trainee'"
                            v-model="deduction_philhealth"
                            label="PhilHealth"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-if="read_ca_only"
                            v-model="deduction_ca"
                            label="Cash Advance"
                            dense
                            outlined
                            readonly
                          ></v-text-field>
                          <v-text-field
                            v-else
                            v-model="deduction_ca"
                            label="Cash Advance"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-model="deduction_coop_loan"
                            label="COOP Loan"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-if="status != 'Trainee'"
                            v-model="deduction_pagibig"
                            label="Pag-Ibig"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-if="read_plan_only"
                            v-model="deduction_plan"
                            label="Plan"
                            dense
                            outlined
                            readonly
                          ></v-text-field>
                          <v-text-field
                            v-else
                            v-model="deduction_plan"
                            label="Plan"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-if="read_coop_save_only"
                            v-model="deduction_coop_save"
                            label="COOP Save"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-model="deduction_late"
                            label="Late"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-model="no_of_absent"
                            label="No. of Absent"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-model="deduction_tax"
                            label="TAX"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-if="read_motor_only"
                            v-model="deduction_motor"
                            label="Motor Finance"
                            dense
                            outlined
                            readonly
                          ></v-text-field>
                          <v-text-field
                            v-else
                            v-model="deduction_motor"
                            label="Motor Finance"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-if="read_other_only"
                            v-model="deduction_orec"
                            label="Other Receivable"
                            dense
                            outlined
                            readonly
                          ></v-text-field>
                          <v-text-field
                            v-else
                            v-model="deduction_orec"
                            label="Other Receivable"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-model="deduction_cash_bond"
                            label="Cash Bond"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-model="deduction_sss_loan"
                            label="SSS Loan"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-model="deduction_pagibig_loan"
                            label="Pag-Ibig Loan"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-model="deduction_others"
                            label="Others"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-if="deduction_others > 0"
                            v-model="other_details"
                            label="Specify Others"
                            dense
                            outlined
                            :rules="rules.default_max_45_character_and_no_empty_rule"
                          ></v-text-field>
                        </v-col>
                        <v-col md="12" cols="12">
                          <v-text-field
                            background-color="warning"
                            style="font-size: xx-large"
                            v-model="net_salary_formated"
                            label="Net Salary"
                            dense
                            outlined
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <!-- alert -->
                <v-col cols="12" v-show="alert">
                  <v-alert color="warning" text class="mb-0">
                    <div class="d-flex align-start">
                      <v-icon color="warning">
                        {{ icons.mdiAlertOutline }}
                      </v-icon>

                      <div class="ms-3">
                        <p class="text-base font-weight-medium mb-1">
                          {{ alert_message }}
                        </p>
                      </div>
                    </div>
                  </v-alert>
                </v-col>
                <v-col cols="12" md="12" v-if="!is_final">
                  <v-btn class="w-full" color="primary" @click="save_employee" v-if="!saving"> Save
                    Incentive
                  </v-btn>
                  <v-progress-circular
                    :size="50"
                    :width="5"
                    color="primary"
                    indeterminate
                    v-if="saving"
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col md="12" cols="12">
        <v-card flat dense>
          <v-card-text class="mt-3">
            <v-row dense>
              <v-col md="12" cols="12">
                <h2>
                  Incentives Data
                  <v-btn class="mt-2" color="success" @click="final_salary"
                         v-if="!saving && !is_final">
                    Final/Approved
                  </v-btn>
                  <v-progress-circular
                    :size="50"
                    :width="5"
                    color="primary"
                    indeterminate
                    v-if="saving"
                  ></v-progress-circular>
                </h2>
                <v-simple-table dense class="mt-2">
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="text-center text-uppercase">Particulars</th>
                      <th class="text-center text-uppercase">Name</th>
                      <th class="text-center text-uppercase">Branch</th>
                      <th class="text-center text-uppercase">Position</th>
                      <th class="text-center text-uppercase">Gross</th>
                      <th class="text-center text-uppercase">Deductions</th>
                      <th class="text-center text-uppercase">Net</th>
                      <th class="text-center text-uppercase">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in salaries_items" :key="item.id">
                      <td class="text-center">
                        {{ item.particulars }}
                      </td>
                      <td class="text-center">
                        {{
                        item.employee.last_name + ',' + item.employee.first_name + ' ' +
                        item.employee.middle_name
                        }}
                      </td>
                      <td class="text-center">
                        {{ item.branch }}
                      </td>
                      <td class="text-center">
                        {{ item.position }}
                      </td>
                      <td class="text-center">
                        {{
                        formatPrice(parseFloat(item.gross_salary)) }}
                      </td>
                      <td class="text-center">
                        {{
                        formatPrice(
                        parseFloat(item.absent_amount) +
                        parseFloat(item.deduction_sss) +
                        parseFloat(item.deduction_philhealth) +
                        parseFloat(item.deduction_pagibig) +
                        parseFloat(item.deduction_loan) +
                        parseFloat(item.deduction_auto_loan) +
                        parseFloat(item.deduction_coop_loan) +
                        parseFloat(item.deduction_late) +
                        parseFloat(item.deduction_coop_save) +
                        parseFloat(item.deduction_ca) +
                        parseFloat(item.deduction_plan) +
                        parseFloat(item.deduction_others) +
                        parseFloat(item.deduction_tax) +
                        parseFloat(item.deduction_orec) +
                        parseFloat(item.deduction_cash_bond) +
                        parseFloat(item.deduction_sss_loan) +
                        parseFloat(item.deduction_pagibig_loan) +
                        parseFloat(item.deduction_motor),
                        )
                        }}
                      </td>
                      <td class="text-center">
                        {{ formatPrice(item.net_salary) }}
                      </td>
                      <td class="text-center">
                        <div v-if="item.is_approved === 0">
                          <v-icon class="mr-2" color="error" @click="actions(item)">
                            {{ icons.mdiPencilOutline }}
                          </v-icon>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="edit_dialog" persistent max-width="80%">
      <v-card>
        <edit-salaries-components v-on:data="selected_month" :key="this.key"
                                  :value="this.edit_value">
        </edit-salaries-components>
      </v-card>
      <v-btn class="mt-4" color="error" @click="edit_dialog = false"> Close</v-btn>
    </v-dialog>

    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {mdiPencilOutline} from '@mdi/js'
  import moment from 'moment'
  import {mapActions, mapGetters} from 'vuex'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import EditSalariesComponents
    from '@/views/input_interface/salary_and_thirten_pay/chapel_incentives/EditSalariesIncentivesComponents'

  const initialState = () => {
    return {
      key: 0,
      saving: false,
      alert: false,
      alert_message: '',
      search_member: [],

      edit_dialog: false,
      edit_value: {},

      month_of_id: '',
      month_of_items: [],
      type_of_employee_items: [
        {id: 1, name: 'Active'},
        {id: 2, name: 'Temporary Deactivate'},
        {id: 3, name: 'Permanent Deactivated'},
      ],
      type_of_employee: '',
      category_id: '',
      category_items: [],
      duration: '',
      batch_no: '',
      isLoading: false,
      search: '',
      search_items: [],
      employee_items: [],
      full_name: '',
      branch: '',
      position: '',
      status: '',
      date_of_employment: '',
      employment_duration: '',
      compensation_rate: 0,
      no_of_days: 0,
      holiday_pay: 0,
      royalty: 0,
      other_incentives: 0,
      holiday_pay_details: '',
      bod_allowance: 0,
      rice_allowance: 0,
      birthday_allowances: 0,
      trainee_allowances: 0,
      incentives_allowances: 0,
      gross_salary: 0,
      gross_salary_formated: '0.00',
      deduction_sss: 0,
      deduction_loan: 0,
      deduction_auto_loan: 0,
      deduction_philhealth: 0,
      deduction_ca: 0,
      deduction_coop_loan: 0,
      deduction_pagibig: 0,
      deduction_plan: 0,
      deduction_coop_save: 0,
      deduction_late: 0,
      deduction_tax: 0,
      no_of_absent: 0,
      deduction_others: 0,
      deduction_motor: 0,
      deduction_orec: 0,
      deduction_cash_bond: 0,
      deduction_sss_loan: 0,
      deduction_pagibig_loan: 0,
      incentives_chapel: 0,
      other_details: '',
      net_salary: 0,
      net_salary_formated: '0.00',
      salaries_items: [],
      data: [],
      selectedDepositId: -1,
      particulars: '',
      category_text: '',
      bank_type: '',
      bank_account_name: '',
      bank_account_no: '',
      headers: [{text: 'NAME', value: 'id', sortable: false}],

      read_loans_only: false,
      read_auto_loans_only: false,
      read_ca_only: false,
      read_other_only: false,
      read_motor_only: false,
      read_plan_only: false,
      read_coop_save_only: false,

      is_final: false,
    }
  }
  export default {
    components: {
      snackBarDialog,
      EditSalariesComponents,
    },
    setup() {
      return {
        icons: {
          mdiPencilOutline,
        },
        is_deleting: false,
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['name', 'employee_id']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('employee', ['search_employee_by_id']),
      ...mapActions('salaries_and_wages', [
        'initialize_salaries_employee',
        'chapel_incentives_data',
        'create_salary_incentives',
        'salaries_incentives_data',
        'salaries_incentives_data_approved',
      ]),
      initialize_data() {
        this.initialize_salaries_employee()
          .then(response => {
            this.month_of_items = response.data[0].month_of
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      reset() {
        this.saving = false
        this.particulars = ''
        this.full_name = ''
        this.branch = ''
        this.position = ''
        this.status = ''
        this.date_of_employment = ''
        this.employment_duration = ''
        this.bank_type = ''
        this.bank_account_name = ''
        this.bank_account_no = ''
        this.compensation_rate = 0
        this.no_of_days = 0
        this.holiday_pay = 0
        this.other_incentives = 0
        this.royalty = 0
        this.holiday_pay_details = ''
        this.bod_allowance = 0
        this.rice_allowance = 0
        this.birthday_allowances = 0
        this.trainee_allowances = 0
        this.incentives_allowances = 0
        this.gross_salary = 0
        this.gross_salary_formated = '0.00'
        this.deduction_sss = 0
        this.deduction_loan = 0
        this.deduction_auto_loan = 0
        this.deduction_philhealth = 0
        this.deduction_ca = 0
        this.deduction_coop_loan = 0
        this.deduction_pagibig = 0
        this.deduction_plan = 0
        this.deduction_coop_save = 0
        this.deduction_late = 0
        this.deduction_tax = 0
        this.deduction_motor = 0
        this.deduction_orec = 0
        this.deduction_cash_bond = 0
        this.deduction_sss_loan = 0
        this.deduction_pagibig_loan = 0
        this.no_of_absent = 0
        this.deduction_others = 0
        this.other_details = ''
        this.net_salary = 0
        this.net_salary_formated = '0.00'
        this.selectedDepositId = -1
      },
      async activerow(value) {
        var au_loan = 0
        var ca_de = 0
        var act_l = 0
        var o_recs = 0
        var moto_f = 0
        var plan_f = 0
        var coop_saveeee = 0
        await this.search_employee_by_id({
          search_word: this.type_of_employee === 'CMI' ? value.cm.employee_id : value.employee_id,
          month_of_id: this.month_of_id,
        })
          .then(response => {
            this.category_id = response.data.category_id
            this.full_name = response.data.last_name + ', ' + response.data.first_name + ' ' + response.data.middle_name
            this.branch = response.data.branch.branch_code
            this.position = response.data.employee_position.position
            this.status = response.data.status
            this.date_of_employment = moment(response.data.date_of_employment, 'YYYY-MM-DD').format('MMMM DD, YYYY')
            var a = moment()
            var b = moment(response.data.date_of_employment, 'YYYY-MM-DD')

            var years = a.diff(b, 'year')
            b.add(years, 'years')

            var months = a.diff(b, 'months')
            b.add(months, 'months')

            var days = a.diff(b, 'days')
            this.employment_duration = years + ' years ' + months + ' months ' + days + ' days'
            this.bank_type = response.data.bank_type_1
            this.bank_account_name = response.data.bank_account_1
            this.bank_account_no = response.data.bank_account_no_1

            if (response.data.salary_deductions.length > 0) {
              response.data.salary_deductions.forEach(function (item) {
                switch (item.type) {
                  case 'Auto Loan':
                    au_loan = parseFloat(au_loan) + parseFloat(item.amount)
                    break
                  case 'Cash Advance':
                    ca_de = parseFloat(ca_de) + parseFloat(item.amount)
                    break
                  case 'Accounting Loan':
                    act_l = parseFloat(act_l) + parseFloat(item.amount)
                    break
                  case 'Other Receivable':
                    o_recs = parseFloat(o_recs) + parseFloat(item.amount)
                    break
                  case 'Motor Finance':
                    moto_f = parseFloat(moto_f) + parseFloat(item.amount)
                    break
                  case 'Plan':
                    plan_f = parseFloat(plan_f) + parseFloat(item.amount)
                    break
                  case 'Coop Save':
                    coop_saveeee = parseFloat(coop_saveeee) + parseFloat(item.amount)
                    break
                }
              })
            }
          })
          .catch(error => {
            console.log(error)
          })
        this.data = value
        this.selectedDepositId = value.id

        this.incentives_chapel = this.type_of_employee === 'CMI' ? parseFloat(value.total_cm_incentives) + parseFloat(value.kaagapay_incentives) + parseFloat(value.total_commission) :
          parseFloat(value.total_incentives)

        var can_eeddit = (this.employee_id === 2 || this.employee_id === 1);
        if (au_loan > 0 && !can_eeddit) {
          this.read_auto_loans_only = true
        }
        if (ca_de > 0 && !can_eeddit) {
          this.read_ca_only = true
        }
        if (act_l > 0 && !can_eeddit) {
          this.read_loans_only = true
        }
        if (o_recs > 0 && !can_eeddit) {
          this.read_other_only = true
        }
        if (moto_f > 0 && !can_eeddit) {
          this.read_motor_only = true
        }
        if (plan_f > 0 && !can_eeddit) {
          this.read_plan_only = true
        }
        if (coop_saveeee > 0 && !can_eeddit) {
          this.read_coop_save_only = true
        }
        this.deduction_auto_loan = au_loan
        this.deduction_ca = ca_de
        this.deduction_loan = act_l
        this.deduction_orec = o_recs
        this.deduction_motor = moto_f
        this.deduction_plan = plan_f
        this.deduction_coop_save = coop_saveeee
        this.computation_of_salary()
      },
      selected_month() {
        this.reset()
        this.edit_dialog = false
        const data = new FormData()
        data.append('month_of', this.month_of_items[this.month_of_items.map(function (x) {
          return x.id
        }).indexOf(this.month_of_id)].month_of)
        data.append('type_of_incentives', this.type_of_employee)
        this.chapel_incentives_data(data)
          .then(response => {
            if (response.data != '') {
              this.employee_items = response.data
            }
            // this.salaries_items = response.data[0].salaries_data
            // this.is_final = response.data[0].is_final
          })
          .catch(error => {
            console.log(error)
          })
        data.append('particulars', this.type_of_employee)
        data.append('month_of_id', this.month_of_id)
        data.append('batch_no', this.batch_no)
        this.salaries_incentives_data(data)
          .then(response => {
            this.salaries_items = response.data[0].salaries_data
            // this.employee_items = response.data[0].employee
            this.is_final = response.data[0].is_final
          })
          .catch(error => {
            console.log(error)
          })
      },
      computation_of_salary() {
        this.check_if_input_number_correct()

        // this.gross_salary =
        //   parseFloat(this.no_of_days) * parseFloat(this.compensation_rate) +
        //   parseFloat(this.bod_allowance) +
        //   parseFloat(this.holiday_pay) +
        //   parseFloat(this.rice_allowance) +
        //   parseFloat(this.birthday_allowances) +
        //   parseFloat(this.trainee_allowances) +
        //   parseFloat(this.incentives_allowances)
        this.gross_salary = ((parseFloat(this.incentives_chapel) < parseFloat(this.royalty) ? parseFloat(this.royalty) : parseFloat(this.incentives_chapel)) + parseFloat(this.other_incentives))
        this.absent_amount = parseFloat(this.no_of_absent) * parseFloat(this.compensation_rate)
        var deductions =
          parseFloat(this.absent_amount) +
          parseFloat(this.deduction_sss) +
          parseFloat(this.deduction_loan) +
          parseFloat(this.deduction_auto_loan) +
          parseFloat(this.deduction_philhealth) +
          parseFloat(this.deduction_ca) +
          parseFloat(this.deduction_coop_loan) +
          parseFloat(this.deduction_pagibig) +
          parseFloat(this.deduction_plan) +
          parseFloat(this.deduction_coop_save) +
          parseFloat(this.deduction_late) +
          parseFloat(this.deduction_tax) +
          parseFloat(this.deduction_motor) +
          parseFloat(this.deduction_orec) +
          parseFloat(this.deduction_cash_bond) +
          parseFloat(this.deduction_pagibig_loan) +
          parseFloat(this.deduction_sss_loan) +
          parseFloat(this.deduction_others)

        this.net_salary = parseFloat(this.gross_salary) - parseFloat(deductions)

        this.gross_salary_formated = this.formatPrice(this.gross_salary)
        this.net_salary_formated = this.formatPrice(this.net_salary)
      },
      check_if_input_number_correct() {
        if (this.no_of_days > 31 || this.no_of_days < 0 || this.no_of_days === '') {
          this.no_of_days = 0
        }
        if (this.holiday_pay < 0 || this.holiday_pay === '') {
          this.holiday_pay = 0
        }
        if (this.royalty < 0 || this.royalty === '') {
          this.royalty = 0
        }
        if (this.other_incentives < 0 || this.other_incentives === '') {
          this.other_incentives = 0
        }
        if (this.rice_allowance < 0 || this.rice_allowance === '') {
          this.rice_allowance = 0
        }
        if (this.birthday_allowances < 0 || this.birthday_allowances === '') {
          this.birthday_allowances = 0
        }
        if (this.trainee_allowances < 0 || this.trainee_allowances === '') {
          this.trainee_allowances = 0
        }
        if (this.incentives_allowances < 0 || this.incentives_allowances === '') {
          this.incentives_allowances = 0
        }
        if (this.bod_allowance < 0 || this.bod_allowance === '') {
          this.bod_allowance = 0
        }
        if (this.deduction_sss < 0 || this.deduction_sss === '') {
          this.deduction_sss = 0
        }
        if (this.deduction_loan < 0 || this.deduction_loan === '') {
          this.deduction_loan = 0
        }
        if (this.deduction_auto_loan < 0 || this.deduction_auto_loan === '') {
          this.deduction_auto_loan = 0
        }
        if (this.deduction_philhealth < 0 || this.deduction_philhealth === '') {
          this.deduction_philhealth = 0
        }
        if (this.deduction_ca < 0 || this.deduction_ca === '') {
          this.deduction_ca = 0
        }
        if (this.deduction_coop_loan < 0 || this.deduction_coop_loan === '') {
          this.deduction_coop_loan = 0
        }
        if (this.deduction_pagibig < 0 || this.deduction_pagibig === '') {
          this.deduction_pagibig = 0
        }
        if (this.deduction_plan < 0 || this.deduction_plan === '') {
          this.deduction_plan = 0
        }
        if (this.deduction_coop_save < 0 || this.deduction_coop_save === '') {
          this.deduction_coop_save = 0
        }
        if (this.deduction_late < 0 || this.deduction_late === '') {
          this.deduction_late = 0
        }
        if (this.no_of_absent < 0 || this.no_of_absent === '') {
          this.no_of_absent = 0
        }
        if (this.deduction_tax < 0 || this.deduction_tax === '') {
          this.deduction_tax = 0
        }
        if (this.deduction_motor < 0 || this.deduction_motor === '') {
          this.deduction_motor = 0
        }
        if (this.deduction_orec < 0 || this.deduction_orec === '') {
          this.deduction_orec = 0
        }
        if (this.deduction_cash_bond < 0 || this.deduction_cash_bond === '') {
          this.deduction_cash_bond = 0
        }
        if (this.deduction_pagibig_loan < 0 || this.deduction_pagibig_loan === '') {
          this.deduction_pagibig_loan = 0
        }
        if (this.deduction_sss_loan < 0 || this.deduction_sss_loan === '') {
          this.deduction_sss_loan = 0
        }
        if (this.deduction_others < 0 || this.deduction_others === '') {
          this.deduction_others = 0
        }
      },
      searching(value) {
        this.search_members_unpaid({
          search_word: value,
        }).then(response => {
          this.search_items = response.data
        })
      },
      get_search_items_info() {
        if (this.search_items.length > 0) {
          var index = this.search_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.search.id)
          if (index != -1) {
            this.selected_search_items = this.search_items[index]
            this.id_no = this.selected_search_items.id_no
            this.name = this.selected_search_items.name
          } else {
            this.name = ''
            this.id_no = ''
          }
        } else {
          this.name = ''
          this.id_no = ''
        }
      },
      actions(item) {
        this.edit_dialog = true
        this.edit_value = item
        this.key++
      },
      save_employee() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('category_id', this.category_id)
          data.append('employee_id', this.type_of_employee === 'CMI' ? this.data.cm.employee_id : this.data.employee_id)
          data.append('month_of_id', this.month_of_id)

          data.append('particulars', this.type_of_employee)

          data.append('batch_no', this.batch_no)
          data.append('branch', this.branch)
          data.append('position', this.position)
          data.append('status', this.status)
          data.append('employment_duration', this.employment_duration)
          data.append('bank_type', this.bank_type)
          data.append('bank_account_name', this.bank_account_name)
          data.append('bank_account_no', this.bank_account_no)

          data.append('royalty', this.royalty)
          data.append('other_incentives', this.other_incentives)
          data.append('chapel_incentives', this.incentives_chapel)
          data.append('gross_salary', this.gross_salary)

          data.append('no_of_absent', this.no_of_absent)
          data.append('absent_amount', this.absent_amount)

          data.append('deduction_sss', this.deduction_sss)
          data.append('deduction_philhealth', this.deduction_philhealth)
          data.append('deduction_pagibig', this.deduction_pagibig)
          data.append('deduction_loan', this.deduction_loan)
          data.append('deduction_auto_loan', this.deduction_auto_loan)
          data.append('deduction_coop_loan', this.deduction_coop_loan)
          data.append('deduction_late', this.deduction_late)
          data.append('deduction_coop_save', this.deduction_coop_save)
          data.append('deduction_ca', this.deduction_ca)
          data.append('deduction_plan', this.deduction_plan)
          data.append('deduction_others', this.deduction_others)
          data.append('other_details', this.other_details)
          data.append('deduction_tax', this.deduction_tax)
          data.append('deduction_motor', this.deduction_motor)
          data.append('deduction_pagibig_loan', this.deduction_pagibig_loan)
          data.append('deduction_sss_loan', this.deduction_sss_loan)
          data.append('deduction_orec', this.deduction_orec)
          data.append('deduction_cash_bond', this.deduction_cash_bond)
          data.append('net_salary', this.net_salary)

          data.append('prepared_by', this.name)

          this.create_salary_incentives(data)
            .then(response => {
              this.change_snackbar({
                show: true,
                color: response.status === 200 ? 'success' : 'error',
                text: response.data,
              })
              this.selected_month()
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
      final_salary() {
        this.saving = true
        this.alert = false
        const data = new FormData()
        data.append('month_of_id', this.month_of_id)
        data.append('particulars', this.type_of_employee)
        data.append('batch_no', this.batch_no)
        this.salaries_incentives_data_approved(data)
          .then(response => {
            this.change_snackbar({
              show: true,
              color: 'success',
              text: response.data,
            })
            this.selected_month()
          })
          .catch(error => {
            this.alert = true
            this.alert_message = error
            this.saving = false
          })
      },
    },
  }
</script>
